.combain-styles{
    display: flex;
}
.titlecard-style{
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;   
    align-content: space-around;
    /* justify-content: space-between; */
    justify-content: center;
}

