.Floating-style{
  position: fixed;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

button,
input {
  font-family: 'Montserrat', sans-serif;
}