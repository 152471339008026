.about-me-title {
    font-size: 40px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgb(92, 91, 15);
    margin: 30px 0 30px 20px;
    /* margin: auto; */
}

.row {
    justify-content: center;
}

.about-name {
    font-size: 40px;
    color: rgb(240, 60, 15);
    font-weight: bolder;
    font-family: ui-mospace;
    padding-top: 15px;
    justify-content: center;
    display: flex;
}

.icon-sections {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}


.about-me-card {
    margin: auto;
    /* align-items: center; */
    justify-content: center;
    /* width: 1200px; */
    /* padding: 20px; */
}

.dots-style {
    font-size: 100px;
}

.column {
    margin: 20px;
}

.about-img {
    /* background-color: rgb(92, 91, 15,0.5); */
    /* box-shadow: 0 20px 8px rgb(0 0 0 / 26%); */
    border-radius: 100px;
    /* padding: 5px; */
}

.img-size {
    border-radius: 100px;
}

.sub-topic {
    font-size: 20px;
    justify-content: center;
    display: flex;
    padding-bottom: 15px;
    margin: 2px 0 0 15px;
    font-weight: 600;
}


.about-sign {
    font-size: 20px;
    justify-content: center;
    display: flex;
    padding-top: 10px;
    font-weight: bolder;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.about-text {
    align-content: space-around;

}


@media screen and (min-width:750px) {
    .about-content {
        margin-top: -250px;


    }

    .contents {
        justify-content: center;
    }
}

@media screen and (max-width:750px) {

    .about-me-title {
        font-size: 30px;
        margin: 10px 0 10px 5px;
        /* margin: auto; */

    }

    .about-content {
        margin-top: 0px;

    }

    .sub-topic {
        padding-left: 15px;
        margin: 2px 0 0 15px;
    }
    .about-name {
        font-size: 30px;
        padding-top: 0px;
    }
}