.buttonstyle{
    display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-top: 10%;
  width: 250px;
  height: 71px;
  line-height: 71px;
  font-size: 22px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(60deg, #0c4281, #b5b8b9);
  text-decoration: none;
  box-shadow: 15px 15px 14px rgb(0 52 177 / 52%);
  opacity: 1;
 
}
.header-images{
    width: 100%;
    height: 1000px;  
}
.buttonstyle:hover{
    background: linear-gradient(60deg, #bebebe, #103947);
    box-shadow: 0 15px 14px rgb(0 0 177 / 52%);
    text-align: end;
    align-items: baseline;
    justify-content: initial;
    padding-left: 25px;
    opacity: 1;
}
.buttona{
    text-align: center;
    color: #fff;
    /* cursor: pointer;
    background: linear-gradient(60deg, #0c4281, #b5b8b9); */
    text-decoration: none;
}
.buttona:hover{
    text-align: center;
    color: #fff;
    /* cursor: pointer;
    background: linear-gradient(60deg, #0c4281, #b5b8b9); */
    text-decoration: none;
}
.butticon{
        opacity: 0.2;
        padding-left: 10px;
        justify-content: center;
        align-items: center;
}
.butticon:hover{
    opacity: 1;
}
.btn-1 {
    font-family: Hack, monospace;
    background: #0F0F6D;
    color: #ffffff;
    cursor: pointer;
    font-size: 2em;
    padding: 1.5rem;
    border: 0;
    transition: all 0.5s;
    border-radius: 10px;
    width: auto;
    position: relative;

    /* after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        position: absolute;
        left: 85%;
        top: 31%;
        right: 5%;
        bottom: 0;
        opacity: 0;

    } */

    /* hover {
        background: #2b2bff;
        transition: all 0.5s;
        border-radius: 10px;
        box-shadow: 0px 6px 15px #0000ff61;
        padding: 1.5rem 3.5rem 1.5rem 1.5rem;

        &::after {
            opacity: 1;
            transition: all 0.5s;

        }
    } */


}
.Section{    
    height: 785px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
}
.Content { 
    width: 100%;
    height: 100px;
}


/* .Left { 
    align-items: center;
    padding-left: 230px;
    padding-top: 180px;
    justify-content: center;
} */
.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;   
    padding-right: 50px;
    padding-left: 50px;
 
  }

.Title { 
    font-size: 55px;
  color: #252525;
  text-shadow: #fff;
  font-weight: 800;  
  align-items: center;
  justify-content: center; 
  padding-top: 25%;
}


.Desc { 
    width: 472px;
  font-size: 20px;
  color: #9ea0ac;
  line-height: 30px;
  margin-top: 58px;
}
.sidebar{
    height: 100%;
    width: 250px;
    list-style: none;
    background-color: rgb(1, 40, 61,0.719);
    position: absolute;
    color: honeydew;
    
}   
.Title h4{
    background-color: rgb(238, 224, 27);
    
    border-width: thin;
    border-radius: 15px;
    padding:0 0 0 5px;
    opacity: 0.5;
}




@media screen and (max-width:960px){
    .Title{
        font-size: 25px;
        /* color: wheat; */
        /* padding-top: 50%; */
        padding-top: 150px;
    }
    .header-images{       
        height: 550px;  
    }
    .Left{
        align-items: center;
    padding-left: 200px;
    padding-top: 180px;
    /* position: relative; */
    /* justify-content: center; */
    }
    .buttonstyle{
        width: 150px;
     height: 51px;
     line-height: 51px;
  font-size: 15px;
    }
}

@media screen and (max-width:550px){
    .Title{
        font-size: 20px;
       top: 0px;
       
    }
    .header-images{       
        height: 350px;  
    }
    .buttonstyle{
        width: 150px;
     height: 31px;
     line-height: 31px;
  font-size: 15px;
  padding-top: 0px;
    }
    .Left{
        align-items: center;
    padding-left: 50px;
    padding-top: 180px;
    position: relative;
    justify-content: center;
    }
    .Title h4{
        background-color: transparent;
        opacity: 1;
    }
}