.container {
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;
    padding: 1rem 0;  
  }
.blog-title{
  display: flex;
    justify-content: center;
    
    font-family: none;
}