.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: initial;
}
.Whats-app-but-sty{
  margin-top: 5px;
}
.popup-inner{
    position: relative;
    width: 100%;
    padding: 32px;
    /* max-width: 640px;   */
    border-radius: 32px;
    color: black;  
  
    background-color: white;
   
}
.form-add-details{
  max-width: fit-content;
}

.form-columns{
    display: grid;
    /* grid-template-columns: 1fr 1fr;   */
    grid-template-columns: auto; 
    color: black;  
    margin-top: 3%;
}
.form-lable-styles{
  padding-right: 5px;
  padding-left: 5px;
  font-weight:bold;
}
.form-Content{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;


  
}
p {
  margin-bottom: 1rem;
}
button {
    padding: 10px 24px;
    background:rgb(12, 43, 83); 
    color: white;
    border-radius: 25px;
    margin-top: 5px;
  }
  button:hover{
    cursor: pointer;
  }
.close-btn{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;   
    height: 32px;
    padding: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;
}
.form-error-msg{
  /* color: rgb(248, 248, 248); */
  background-color: rgb(129, 127, 127);
  color: whitesmoke;
  border-radius: 25px;
  padding: 8px;
  margin-top: 5px;
}
.form-cards{
  padding:  20px;
  margin: 5px;
}
.form-error input {
  border-color: red;
  background: #fbdada;
}
.form-error label {
  color: red;
  
}
form input:focus {
  outline: none;  
  border-color: rgba(23, 156, 189, 0.801);
  background: #f6dbfc;
}
form input{
  border-radius: 25px;
  padding: 5px;  
}

@media screen and (max-width: 750px) {
  .popup-inner{
    position: relative;
    width: 100%;
    /* padding: 20px; */
    /* max-width: 500px;   */
    border-radius: 32px;
   /* align-items: center; */
  }
  .close-btn{       
    position: static;
  }
  .form-columns{
    grid-template-columns: none;
  }
}