.blog-wrap {
    max-width: 900px;
    margin: 0 auto;
  }

  h2,h3,.subheader{
    /* font-weight: 900; */
    /* font-style: italic; */
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: none;
  }

  .subheader, .subtitle{
    padding-top: 10px;
  }
  h6{
    padding-top: 5px;
  }
  
  .blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #040404;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    font-weight: bolder;
    margin-top: 10px;
  }
  .blog-wrap header {
    text-align: center;
  }
  
  .blog-date {
    padding-top: 5px;
    font-size: 0.8rem;
    color: #343333;
    font-weight: 500;
  }
  
  .blog-wrap img {
    width: 100%;
  }
  
  .blog-subCategory {
    display: flex;
    justify-content: center;
  }
  .blog-subCategory > div {
    margin: 1rem;
  }
  .blog-desc {
    padding: 1rem;
    margin-top: 1rem;
    font-family: cursive;
  }