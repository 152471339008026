.footer-container {
  background-color: #1c2237;
  padding: 2% 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid;
  border-top-color: rgb(224 146 0);
}
.contact-number{
  color: #e9e9e9;
  padding: 5px;
  font-size: 15px;
}
.mail-id{
  color: #e9e9e9; 
  font-size: 15px;
  padding: 2px; 
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 24px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 16px; */
  text-align: left;
  /* width: 160px; */
  box-sizing: border-box;  
  flex-wrap: wrap;
  margin: 0 5px 0 5px;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
  padding-right: 5%;
}

.social-media {
  /* max-width: 1000px; */
  /* display: inline; */
  align-items: center;
  justify-content: center;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 90%; */
  /* max-width: 1000px; */
  /* margin: 40px auto 0 auto; */
}

.social-icons {
  /* padding-right: 5%; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 240px;
  /* margin-top: -20px; */  
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.website-name{
  align-items: center;
  justify-content: center;
  color: #fff;    
  font-size: 20px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  margin-left: 3%;
  margin-right: 3%;
}
.our-work-items{
  color: #fff;
  margin-bottom: 0.5rem;
  cursor: default;
}
.map-img{
  border-radius: 50px;
  width: 80%;
  height: 600px;
  margin: 5px 0 10px 0;
}
.our-work-url{
  cursor: pointer;
  color: #fff;
}
@media screen and (max-width: 820px) {
  .footer-links {
    /* padding-top: 2rem; */
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .social-logo{
    align-items: center;
    justify-content: center;
    /* margin-left: 0; */
    margin-bottom:5px;
  }
 
  .website-rights{
    align-items: center;
    justify-content: center;
  }
  .social-icons{
    padding: 5px 1% 0 2%;
    /* align-items: center;
    justify-content: center; */
  }
  .website-name{
    align-items: center;
    justify-content: center;
    color: #fff; 
    font-size:large;   
    padding-top: 3px
  }
}

